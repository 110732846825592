<template>
  <section class="noAuth">
    <el-alert :title="`对不起,无权操作此页面！`"  :description="`您的角色为--${roleName}`"  type="error" show-icon :closable ="false"> </el-alert>
    <el-row justify ="center">
      <el-col :span="24" :push ="10">
        <el-button  size="small" type="primary" @click.native="logout">退出登录</el-button>
      </el-col> 
    </el-row>
  </section>
</template>

<script>
export default {
  name: "noAuth",
  data() {
    return {
      roleName: this.$route.query.roleName
    };
  },
  methods: {
    logout () {
      window.localStorage.clear()
		  sessionStorage.removeItem('userInfo');
      this.$router.push('/login');
	  }
  },
  mounted() {
      this.$store.commit('HIDEFULLLOAD', true)
  }
};
</script>

<style  scoped>
  .noAuth .el-alert {
    margin-bottom: 30px;
  } 
</style>